$(document).on("turbo:submit-end", function (ev) {
  if (
    ev.originalEvent.target == document.querySelector("#modal_ticket form")
  ) {
    if (ev.originalEvent.detail.success) {
      $("#modal_ticket").modal("hide");
      $("#modal_ticket form").get(0).reset();
    }
  }
});
