// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
require("jquery");

require("chartkick/dist/chartkick");
require("chart.js");

Rails.start()
ActiveStorage.start()

import "../src/app";
import "../src/boards";
import "../src/invite_links";
import "../src/tickets";
import "../src/comments";

require("trix")
require("@rails/actiontext")

import "../src/trix-editor-overrides";
import "controllers"
