$(document).on("turbo:load", function () {
  $(".toast").toast("show");
  $("select").each(function () {
    $(this)
      .find('option[value="' + $(this).attr("value") + '"]')
      .prop("selected", true);
  });

  $("body").on("click", "a[data-toggle='password-text']", function () {
    let target = $(this).data("target");
    let current_type = $(target).attr("type");

    if (current_type == "password") {
      $(target).attr("type", "text")
    } else {
      $(target).attr("type", "password")
    }
  });

  $("body").on("change", ".custom-input-file", function () {
    var fileName = $(this).val().split("\\").pop();
    $('label[for="' + this.id + '"] > .custom-input-file-label').html(fileName);
  });
});

$(document).on("turbo:submit-start", function(ev){
  let form = ev.originalEvent.target
  $(form).find("[type='submit']").prop('disabled', true);
})

$(document).on("turbo:submit-end", function (ev) {
  let form = ev.originalEvent.target
  $(form).find("[type='submit']").prop('disabled', false);
})