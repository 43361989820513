$(document).on("turbo:submit-end", function (ev) {
  if (
    ev.originalEvent.target == document.querySelector("#modal_board_form form")
  ) {
    if (ev.originalEvent.detail.success) {
      $("#modal_board_form").modal("hide");
      $("#modal_board_form form").get(0).reset();
    }
  }
});

$(document).on("turbo:load", function (ev) {
  function turboLgEnable(x) {
    if (x.matches) {
      $(".turbo-lg-enable").attr("data-turbo", true);
    } else {
      $(".turbo-lg-enable").each(function () {
        $(this).attr("href", `${$(this).attr('href')}?mobile=true`);
        $(this).attr("data-turbo", false);
      });
    }
  }

  var x = window.matchMedia("(min-width: 992px)");
  turboLgEnable(x) // Call listener function at run time
  x.addListener(turboLgEnable) // Attach listener function on state changes

  if ($("turbo-frame#tickets").length) {
    if($("turbo-frame#tickets > turbo-frame > a[data-turbo=true]").length) {
      if($("turbo-frame#tickets > turbo-frame > a.selected[data-turbo=true]").length) {
        $("turbo-frame#tickets > turbo-frame > a.selected[data-turbo=true]")[0].click()
      } else {
        $("turbo-frame#tickets > turbo-frame > a[data-turbo=true]")[0].click();
      }
    }
  }
})
