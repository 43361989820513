$(document).on("turbo:submit-end", function (ev) {
  if (
    ev.originalEvent.target ==
    document.querySelector("#modal_comment form")
  ) {
    if (ev.originalEvent.detail.success) {
      $("#modal_comment").modal("hide");
      $("#modal_comment form").get(0).reset();
    }
  }
});